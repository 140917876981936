import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { useWindowEvent } from '../../src/utils/globalHooks';
import { window, document } from 'browser-monads';
import theme from '../../src/theme';
import '../../src/css/index.css';

export default function TopLayout(props) {
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  // how to use full height: `var(--vh, 1vh) * 100`
  const setCorrectViewportHeight = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  useEffect(() => {
    setCorrectViewportHeight();
  }, []);
  useWindowEvent('resize', setCorrectViewportHeight);

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node
};
