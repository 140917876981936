/* eslint-disable import/prefer-default-export, react/prop-types */
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import TopLayout from './TopLayout';

export const onClientEntry = () => {
  ReactGA.initialize('UA-137583012-4');
};

export const onInitialClientRender = () => {
};

export const onRouteUpdate = ({ location }) => {
  console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
  if (process.env.NODE_ENV === 'production') {
    ReactGA.pageview((location || {}).pathname);
  }
};

export const wrapRootElement = ({ element }) => {
  return <TopLayout>{element}</TopLayout>;
};
