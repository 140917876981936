// @flow

import { useEffect, useRef } from 'react';

const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};

// https://stackoverflow.com/a/54159564
const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

  return [ htmlElRef, setFocus ];
};

export {
  useWindowEvent,
  useFocus
};
