import { createMuiTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

// Custom theme for this app
// default theme: https://material-ui.com/customization/default-theme/?expand-path=$.typography#explore
const theme = createMuiTheme();

theme.squareplan = {
  blogWindow: {
    width: {
      width: 680,
      maxWidth: 680,
      [theme.breakpoints.down('sm')]: {
        width: `calc(100vw - ${theme.spacing(8)}px)`
      }
    }
  },
  window: {
    width: {
      width: 1000,
      maxWidth: 1000,
      [theme.breakpoints.only('xs')]: {
        width: `calc(100vw - ${theme.spacing(10)}px)`
      },
      [theme.breakpoints.only('sm')]: {
        width: `calc(100vw - ${theme.spacing(16)}px)`
      },
      [theme.breakpoints.up('md')]: {
        width: `calc(100vw - ${theme.spacing(36)}px)`
      }
    }
  },
  footer: {
    height: {
      minHeight: 208,
      [theme.breakpoints.down('xs')]: {
        height: 'auto'
      }
    },
    fontSize: '0.87rem'
  },
  header: {
    height: {
      height: 82,
      [theme.breakpoints.down('xs')]: {
        height: 82
      }
    }
  },
  globalCustomStyles: {
    // to add to @global in main template stylesheets.
    a: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  quote: injectedStyles => ({
    position: 'relative',
    width: '60%',
    margin: '0 auto',
    padding: `0px ${50 + theme.spacing(3)}px`,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      padding: 0
    },
    '& p': {
      // quote itself
      fontWeight: 600,
      fontSize: '1.6rem',
      textShadow: 'none'
    },
    '& + p': {
      // quote caption
      fontFamily: ['Product Sans', 'sans-serif'].join(','),
      fontSize: '1rem',
      fontWeight: 600,
      textShadow: 'none',
      width: '60%',
      margin: '0 auto',
      marginTop: theme.spacing(2),
      padding: `0px ${50 + theme.spacing(3)}px`,
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 50px)',
        padding: 0,
        marginLeft: 0
      }
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: -theme.spacing(1),
      backgroundImage: `url('/quote-open.svg')`,
      backgroundRepeat: 'no-repeat',
      display: 'inline-block',
      color: `${theme.palette.text[injectedStyles.color || 'secondary']}`,
      fontSize: '3rem',
      width: 50,
      height: 50,
      [theme.breakpoints.down('xs')]: {
        top: -(50 - 2)
      }
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      right: 0,
      bottom: -theme.spacing(1),
      backgroundImage: `url('/quote-close.svg')`,
      backgroundRepeat: 'no-repeat',
      display: 'inline-block',
      color: `${theme.palette.text[injectedStyles.color || 'secondary']}`,
      fontSize: '3rem',
      width: 50,
      height: 50,
      [theme.breakpoints.down('xs')]: {
        bottom: -54
      }
    }
  })
};

theme.palette = {
  ...theme.palette,
  text: {
    ...theme.palette.text,
    primary: '#333333',
    secondary: 'white',
    gray: '#767676',
    disabled: '#7A7A7A'
  },
  primary: {
    previous: '#2296F3', // primary color in previous theme
    main: '#5c6bc0'
  },
  secondary: {
    main: '#FFEB3B'
  },
  background: {
    paper: '#fff',
    footer: '#f5f5f5',
    default: '#fafafa',
    blue: '#2296F3',
    yellow: '#FFEB3B'
  },
  custom: {
    backgroundTextInfo: '#5b5b5b',
    neoYellow: {
      main: '#FFEB3B',
      dark: '#EDDA37'
    },
    neoViolet: {
      main: '#5C6BC0'
    }
  },
  border: {
    color: 'hsl(0,0%,80%)',
    radius: 30
  }
};

theme.contentfulThemes = {
  backgroundNeoViolet: {
    backgroundColor: theme.palette.custom.neoViolet.main
  },
  backgroundNeoYellow: {
    backgroundColor: theme.palette.custom.neoYellow.main
  },
  textNeoViolet: {
    color: theme.palette.custom.neoViolet.main,
    '& p': {
      color: theme.palette.custom.neoViolet.main
    }
  },
  textNeoYellow: {
    color: theme.palette.custom.neoYellow.main,
    '& p': {
      color: theme.palette.custom.neoYellow.main
    }
  },
  textWhite: {
    color: 'white',
    '& p': {
      color: 'white'
    }
  },
  h1NeoViolet: {
    color: theme.palette.custom.neoViolet.main,
    '& .MuiTypography-h1': {
      color: theme.palette.custom.neoViolet.main,
      marginBottom: 0
    }
  },
  h1NeoYellow: {
    color: theme.palette.custom.neoYellow.main,
    '& .MuiTypography-h1': {
      color: theme.palette.custom.neoYellow.main,
      marginBottom: 0
    }
  },
  h2NeoViolet: {
    color: theme.palette.custom.neoViolet.main,
    '& .MuiTypography-h2': {
      color: theme.palette.custom.neoViolet.main
    }
  },
  h2NeoYellow: {
    color: theme.palette.custom.neoYellow.main,
    '& .MuiTypography-h2': {
      color: theme.palette.custom.neoYellow.main
    }
  },
  h3NeoViolet: {
    color: theme.palette.custom.neoViolet.main,
    '& .MuiTypography-h3': {
      color: theme.palette.custom.neoViolet.main
    }
  },
  h3NeoYellow: {
    color: theme.palette.custom.neoYellow.main,
    '& .MuiTypography-h3': {
      color: theme.palette.custom.neoYellow.main
    }
  },
  h4NeoViolet: {
    color: theme.palette.custom.neoViolet.main,
    '& .MuiTypography-h4': {
      color: theme.palette.custom.neoViolet.main
    }
  },
  h4NeoYellow: {
    color: theme.palette.custom.neoYellow.main,
    '& .MuiTypography-h4': {
      color: theme.palette.custom.neoYellow.main
    }
  },
  h4White: {
    color: 'white',
    '& p, & .MuiTypography-h4': {
      color: 'white'
    }
  },
  h4Black: {
    color: '#333333',
    '& p, & .MuiTypography-h4': {
      color: '#333333'
    }
  },
  h5White: {
    color: 'white',
    '& p, & .MuiTypography-h5': {
      color: 'white'
    }
  },
  h5Black: {
    color: '#333333',
    '& p, & .MuiTypography-h5': {
      color: '#333333'
    }
  },
  imgShadow: {
    '& img': {
      filter: 'drop-shadow(6px 6px 8px hsl(0,0%,0%,60%))'
    },
    '& a img': {
      filter: 'none'
    }
  }
};

theme.typography = {
  ...theme.typography,
  root: {
    fontSize: '1.1rem',
    color: '#333333',
    fontFamily: ['Product Sans', 'sans-serif'].join(',')
  },
  h1: {
    ...theme.typography.h1,
    fontSize: '3.3rem',
    fontWeight: 800,
    lineHeight: 1.1,
    marginBottom: theme.spacing(2),
    // letterSpacing: '0.6px',
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.6rem'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.3rem',
      lineHeight: 1.1,
      textAlign: 'center'
    }
  },
  h2: {
    ...theme.typography.h2,
    fontSize: '2.4rem',
    fontWeight: 700,
    lineHeight: 1.2,
    color: theme.palette.custom.neoViolet.main,
    marginBottom: theme.spacing(2.5),
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem',
      lineHeight: 1.3
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
      lineHeight: 1.3,
      textAlign: 'center'
    }
  },
  h3: {
    ...theme.typography.h3,
    fontSize: '1.6rem',
    fontWeight: 700,
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    lineHeight: 1.4,
    marginBottom: theme.spacing(0.75),
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.6rem',
      lineHeight: 1.3,
      textAlign: 'center'
    }
  },
  h4: {
    ...theme.typography.h4,
    fontSize: '1.4rem',
    fontWeight: 700,
    lineHeight: 1.5,
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
      textAlign: 'center'
    }
  },
  h5: {
    ...theme.typography.h5,
    fontSize: '1.3rem',
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    lineHeight: 1.5,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
      textAlign: 'center'
    }
  },
  h6: {
    ...theme.typography.h6,
    lineHeight: 1.5,
    fontSize: '0.98rem',
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  body1: {
    color: '#333333',
    fontSize: '1.1rem',
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap',
    lineHeight: 1.5,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  body2: {
    color: '#333333',
    fontSize: '1.1rem',
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap'
  },
  button: {
    fontSize: '1.1rem',
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    fontWeight: 700
  },
  pre: {
    fontFamily: ['Product Sans', 'sans-serif'].join(','),
    whiteSpace: 'pre-wrap'
  }
};

theme.overrides = {
  MuiButton: {
    root: {},
    label: {
      textTransform: 'none'
    },
    outlinedPrimary: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    },
    outlinedSecondary: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 0,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      '&:hover': {
        border: `2px solid ${theme.palette.background.paper}`,
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    }
  },
  MuiTabs: {
    scrollButtons: {
      color: 'red'
    }
  },
  MuiTab: {
    textColorPrimary: {
      fontSize: '1.6rem',
      fontWeight: 600,
      color: theme.palette.text.gray,
      '&.Mui-selected': {
        color: theme.palette.text.primary,
        fontWeight: 600
      }
    }
  }
};

theme.props = {
  ...theme.props,
  MuiButtonBase: {
    disableRipple: true
  }
};

export default theme;
